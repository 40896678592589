/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import CheckSvg from 'components/common/icon/check/CheckSvg';
import { ICONS } from 'constants/icons';
import { COLORS } from 'data/color';
import { LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import useMapDirection from 'hooks/map/trigger/useMapDirection';
import { addBuilding } from 'map/control/building';
import { changeCamera, moveCamera } from 'map/control/camera';
import { changeFloor } from 'map/control/floor';
import { changeMapMinMaxZoom } from 'map/control/zoom';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import useCategoryGroupStore from 'store/categoryGroup';
import useColorSetStore from 'store/colorSet';
import useFloorStore from 'store/floor';
import useModalStore from 'store/modal';
import usePointStore from 'store/point';
import usePopupStore from 'store/popup';
import useTimerStore from 'store/timer';
import useTrackingStore from 'store/tracking';
import { CategoryFeature } from 'types/category/category.type';
import { PathType } from 'types/common/path.type';
import { StorageLocation } from 'types/origin/originLocation.type';
import { Point } from 'types/point/point.type';
import { TenantWithPointId } from 'types/tenant/tenant.type';
import { changeMapDrawMobileMapPosition, changeOriginMapDrawValue } from 'util/map-draw/changeMapDrawValue';
import { returnObjectPosition } from 'util/position/returnCurrentPosition';
import styles from './SettingCurrentLocationButton.module.scss';

export enum LocationButton {
  LIST_FULL = 'LIST_FULL',
  POPUP_SMALL = 'POPUP_SMALL',
  POPUP_INNER = 'POPUP_INNER',
}

type Props = {
  disabled?: boolean;
  closeModal?: () => void;
  clickedTenant: TenantWithPointId | null;
  buttonType: LocationButton;
};

const SettingCurrentLocationButton = ({ disabled, closeModal, clickedTenant, buttonType }: Props) => {
  const popupStore = usePopupStore();
  const floorStore = useFloorStore();
  const timerStore = useTimerStore();
  const colorSetStore = useColorSetStore();
  const pointStore = usePointStore();
  const categoryStore = useCategoryGroupStore();
  const modalStore = useModalStore();
  const trackingStore = useTrackingStore();

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { clientPath } = useParams();

  const { switchActionByTrackingType } = useMapDirection();

  // 팝업 열고 3초 뒤 닫기
  const closeToastAfterSeconds = () => {
    popupStore.setOpenToastPopup(true);
    popupStore.setToastPopupMessage(`${t(`popup.locationChange`)}`);

    timerStore.closeToastPopupAfterSeconds(() => popupStore.setOpenToastPopup(false));
  };

  // 선택된 point 변경
  const changePoint = (pointId: string) => {
    const findPoint: Point | undefined = pointStore.pointsMap?.get(pointId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);
      // map draw 값 변경
      changeMapDrawMobileMapPosition(findPoint.mobile);

      // 기기 포인트 최소/최대 줌레벨 적용
      changeMapMinMaxZoom(Number(findPoint.mobile.mapMinZoom), Number(findPoint.mobile.mapMaxZoom));

      // 기기 방향각, 줌레벨 적용
      changeCamera({
        rotation: Number(findPoint.mobile.rotation),
        zoom: Number(findPoint.mobile.zoom),
        transition: true,
      });
    }
  };

  // 카테고리 리스트 변경
  const changeCategories = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories = categoryStore.mapFacilityCategoriesMap.get(pointId);
      if (findCategories) {
        categoryStore.setCategoryList(CategoryFeature.mapFacility, findCategories);
      }
    }
  };

  // 층 목록 변경
  const changeFloors = (pointId: string) => {
    const findFloors = floorStore.pointFloorsMap?.get(pointId);
    if (findFloors) {
      floorStore.setFloors(findFloors);
    }
  };

  // 현재위치로 선택 버튼 클릭 시
  const onChangeOrigin = async () => {
    if (clickedTenant) {
      modalStore.setOpenTenantListModal(false);

      const currentPosition = returnObjectPosition(clickedTenant.content.objectId);
      const currentFloorId = clickedTenant.content.floorId;
      if (!currentPosition) {
        return;
      }

      const originLocation: StorageLocation = {
        id: clickedTenant.id,
        name: clickedTenant.name,
        position: currentPosition,
        floorId: currentFloorId,
        objectId: clickedTenant.content.objectId,
        pointId: clickedTenant.pointId,
        buildingId: clickedTenant.content.buildingId,
      };

      // 로컬스토리지에 선택한 테넌트 정보 일부 저장
      localStorage.setItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY, JSON.stringify(originLocation));
      // 현재 위치 변경
      changeOriginMapDrawValue(originLocation);

      // 층
      floorStore.setCurrentFloorId(currentFloorId);

      // 층 목록
      changeFloors(originLocation.pointId);

      // 포인트
      changePoint(originLocation.pointId);

      // 카테고리
      changeCategories(originLocation.pointId);

      // 층별안내일 경우에만 지도 층 이동한다
      if (pathname === `/web/${clientPath}/${PathType.floor}`) {
        /**
         * 지도의 빌딩 이동 및 층 이동
         *
         * 같은 빌딩이라면 changeFloor 해야 하고 다른 빌딩이라면 addBuilding 해야함.
         * 이유 : 2024 0404 기준 addBuilding 시 이미 context 에 같은 빌딩 있으면 api 내부적으로 return 하고 있음.
         * 추후 changeFloor 메서드로 다른 빌딩까지 이동할 수 있도록 api 업데이트 예정이라고 들음.
         */
        if (pointStore.selectedPoint?.id === originLocation.pointId) {
          await changeFloor(originLocation.floorId);
        } else {
          await addBuilding(originLocation.buildingId, originLocation.floorId);
        }

        // 변경된 현재위치로 gps 찍고 카메라 이동
        await switchActionByTrackingType(trackingStore.trackingType);

        // 기기 위치로 카메라 이동
        moveCamera(originLocation.position);
      }

      if (closeModal) {
        closeModal();
      }
      closeToastAfterSeconds();
    }
  };

  // 버튼 배경색
  const handleBtnBackground = () => {
    if (disabled) {
      return COLORS.GRAY_CC;
    }

    if (!disabled && buttonType === LocationButton.LIST_FULL) {
      return colorSetStore.main;
    }

    if (!disabled && buttonType === LocationButton.POPUP_SMALL) {
      return COLORS.BLUE_BLACK;
    }

    return colorSetStore.main;
  };

  // 버튼 글자, 아이콘 색
  const handleBtnText = () => {
    if (disabled) {
      return colorSetStore.button.text;
    }

    if (!disabled && buttonType === LocationButton.LIST_FULL) {
      return COLORS.WHITE;
    }

    if (!disabled && buttonType === LocationButton.POPUP_SMALL) {
      return COLORS.GRAY_CC;
    }

    return colorSetStore.button.text;
  };

  return (
    <>
      {buttonType === LocationButton.POPUP_INNER ? (
        <div className={styles.popup_inner_type} onClick={onChangeOrigin}>
          <img src={ICONS.MAP} alt='현재위치 지정 버튼 이미지' />
          <span> {t('button.assignOrigin')}</span>
        </div>
      ) : (
        <button
          style={{
            background: `${handleBtnBackground()}`,
          }}
          onClick={onChangeOrigin}
          disabled={disabled}
          className={`${styles.btn} ${disabled && styles.disabled}    
          ${buttonType === LocationButton.LIST_FULL && !disabled && styles.list_full}
          ${buttonType === LocationButton.POPUP_SMALL && !disabled && styles.popup_small}
        `}
          type='button'
        >
          {buttonType === LocationButton.LIST_FULL && <CheckSvg color={handleBtnText()} />}

          <pre
            className={styles.text}
            style={{
              color: handleBtnText(),
            }}
          >
            {t(buttonType === LocationButton.LIST_FULL ? 'button.selectOrigin' : 'button.assignOrigin')}
          </pre>
        </button>
      )}
    </>
  );
};
export default SettingCurrentLocationButton;
