/* eslint-disable react/jsx-no-useless-fragment */
import ButtonLoader from 'components/common/loader/button/ButtonLoader';
import { LOCAL_STORAGE_ORIGIN_LOCATION_KEY } from 'data/localStorageKey';
import useCheckValidRoute from 'hooks/map/check/useCheckValidRoute';
import { t } from 'i18next';
import { clearAllMarkers } from 'map/control/common/clear';
import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useLocation } from 'react-router-dom';
import useCommonStore from 'store/common';
import useShowStore from 'store/show';
import useTenantStore from 'store/tenant';
import { PathType } from 'types/common/path.type';
import { StorageLocation } from 'types/origin/originLocation.type';
import MapLocationButton from '../button/map-action/MapLocationButton';
import MapNavigationButton from '../button/map-action/MapNavigationButton';
import MapTrackingButton from '../button/map-action/MapTrackingButton';
import CloseButton from '../close/CloseButton';
import styles from './SimpleTenantPopup.module.scss';
import SimpleTenantPopupBody from './body/SimpleTenantPopupBody';

type Props = {
  isOpenPopup?: boolean;
  closeModal: () => void;
  closeWithButton?: () => void;
};

const SimpleTenantPopup = ({ isOpenPopup, closeModal, closeWithButton }: Props) => {
  // store
  const tenantStore = useTenantStore();
  const showStore = useShowStore();
  const commonStore = useCommonStore();

  // hook
  const { checkValidRoute, routeLoading } = useCheckValidRoute();
  const location = useLocation();
  // var
  const storageOriginLocation = localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY);
  // state
  const [startLocation, setStartLocation] = useState<StorageLocation>();

  // 현재위치로 지정된 테넌트인지
  const myLocation = startLocation?.id === tenantStore.currentTenant?.id;

  // 테넌트 목록에서 넘어온 경우 (지도 클릭 x)
  const handleShowNavi = () => {
    if (tenantStore.currentTenant) {
      // 길안내 가능한지 체크
      checkValidRoute(tenantStore.currentTenant);
    }

    // 현재위치 설정 안되어있다면 버튼 노출
    if (!localStorage.getItem(LOCAL_STORAGE_ORIGIN_LOCATION_KEY)) {
      showStore.setShowNavi(true);
      return;
    }

    // 현재위치라면 버튼 비노출
    if (myLocation) {
      return showStore.setShowNavi(false);
    }

    // 현재위치 아니고 object id 다르다면 버튼 노출
    if (showStore.isDiffObjectId) {
      return showStore.setShowNavi(true);
    }

    // 현재위치 아니고 object id 같다면 버튼 비노출
    return showStore.setShowNavi(false);
  };

  const splitUrl = (url: string) => {
    // '/'로 URL을 분리하고 빈 값을 제거
    const parts = url.split('/').filter(Boolean);
    // 마지막 부분
    const page = parts[parts.length - 1]; // `floor`
    return page;
  };

  useEffect(() => {
    return () => {
      clearAllMarkers();
    };
  }, []);

  useEffect(() => {
    if (storageOriginLocation) {
      setStartLocation(JSON.parse(storageOriginLocation));
    }
  }, [storageOriginLocation]);

  useEffect(() => {
    handleShowNavi();
  }, [startLocation, tenantStore.currentTenant, showStore.isDiffObjectId]);

  // 위치확인
  const checkLocation = () => {
    if (location.pathname === `/${PathType.floor}` || splitUrl(location.pathname) === `${PathType.floor}`) {
      return true;
    }
    return false;
  };

  return (
    <OutsideClickHandler onOutsideClick={closeWithButton ?? closeModal}>
      <div className={`${styles.simple_popup}  ${isOpenPopup ? styles.open : styles.close}`}>
        <div className={styles.close_btn_wrapper}>
          <CloseButton closePopup={closeWithButton ?? closeModal} />
        </div>

        {/* popup */}
        <div className={styles.popup_wrapper}>
          <SimpleTenantPopupBody />

          <div className={styles.buttons_wrapper}>
            {routeLoading ? (
              <ButtonLoader />
            ) : (
              <>
                {/* 1030 inner button 주석 */}
                {/* 현재위치 지정 */}
                {/* {!myLocation && (
                  <SettingCurrentLocationButton
                    buttonType={LocationButton.POPUP_SMALL}
                    clickedTenant={tenantStore.currentTenant}
                  />
                )} */}

                {/* 위치확인 버튼 - 층별안내 아닐 경우 노출 */}
                {!checkLocation() && <MapLocationButton closePopup={closeModal} />}

                {/* 길찾기 버튼 (Indoor Tracking) */}
                {showStore.showNavi && (
                  <>
                    {commonStore.hasMachineId ? (
                      <MapTrackingButton closePopup={closeModal} />
                    ) : (
                      <MapNavigationButton buttonText={t(`button.navigation`)} closePopup={closeModal} />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};
export default SimpleTenantPopup;
