import { Machine } from 'types/machine/machine.type';
import DateTime from 'util/date-time/dateTime';
import { create } from 'zustand';

type MachineStore = {
  machine: Machine | null;
  setMachine: (machine: Machine) => void;

  getClosedDays: () => string[];
};

const useMachineStore = create<MachineStore>((set, get) => ({
  machine: null,
  setMachine(machine: Machine) {
    set({ machine });
  },

  // TODO: hook 혹은 util 로 변경
  getClosedDays() {
    const result = get()
      .machine?.point.closedDays.sort((a, b) => {
        return new Date(a).getTime() - new Date(b).getTime();
      })
      .map(day => new DateTime().convertDateYear(day));

    return result || [];
  },
}));

export default useMachineStore;
